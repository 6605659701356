<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const userId = computed(() => store.getters['auth/userId'])
const isCurrentUser = computed(() => userId.value === props.data.byIdentity.id)

const message = computed(()=>{
  const data = props.data
  const deletedBy = isCurrentUser.value
      ? t('components.notificationsSider.you')
      : data.byIdentity?.name || `${data.byIdentity?.firstName || ''} ${data.byIdentity?.lastName || ''}`.trim()
  const mediaCount = data.media.length
  const mediaName = data.media.slice(0, 2).map(media => media.name).join(', ')

  if (mediaCount > 2) {
    const overflow = mediaCount - 2
    return t('components.notificationsSider.mediaDeletedMoreThanDouble', { deletedBy, mediaNames: mediaName, overflow }, overflow)
  }
  return mediaCount === 1
    ? t('components.notificationsSider.mediaDeletedSingle', { deletedBy, mediaName })
    : t('components.notificationsSider.mediaDeletedDouble', { deletedBy, mediaNames: mediaName })
})

</script>

<template>
  {{ message }}
</template>

<style scoped lang="less">

</style>
