<script setup>
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const store = useStore()

const type = ref(null)
const data = ref(null)
const message = ref(null)

const mapPropsToState = () => {
  const { newBillingInfo, previousBillingInfo } = props.data
  if (newBillingInfo.status !== previousBillingInfo.status || newBillingInfo.tier !== previousBillingInfo.tier) {
    return processSubscriptionChange(newBillingInfo)
  } else if (newBillingInfo.billingDevicesQuantity !== previousBillingInfo.billingDevicesQuantity) {
    return processDeviceChange(newBillingInfo, previousBillingInfo)
  }
}

const processSubscriptionChange = (newBillingInfo) => {
  if (newBillingInfo.status === 'paid') {
    if (['business-enterprize', 'education-enterprize'].includes(newBillingInfo.tier)) {
      message.value = t('components.notificationsSider.planUpgradedToEnterprise')
    } else if (newBillingInfo.tier === 'business-growth') {
      message.value = t('components.notificationsSider.planUpgradedToBusinessGrowth')
    } else if (newBillingInfo.tier === 'education-growth') {
      message.value = t('components.notificationsSider.planUpgradedToEducationGrowth')
    }
  } else if (newBillingInfo.status === 'past_due') {
    type.value = 'SUBSCRIPTION_PAST_DUE'
  } else if (newBillingInfo.status === 'unpaid') {
    type.value = 'SUBSCRIPTION_UNPAID'
  } else if (newBillingInfo.status === 'inactive') {
    type.value = 'SUBSCRIPTION_TO_INACTIVE'
  }
}

const processDeviceChange = (newBillingInfo, previousBillingInfo) => {
  const deviceDiff = newBillingInfo.billingDevicesQuantity - previousBillingInfo.billingDevicesQuantity
  if (deviceDiff > 0) {
    type.value = 'SUBSCRIPTION_UPGRADED';
    data.value = { addedDevices: deviceDiff, currentDevices: newBillingInfo.billingDevicesQuantity }
  } else {
    type.value = 'SUBSCRIPTION_DOWNGRADED';
    data.value = { removedDevices: -deviceDiff, currentDevices: newBillingInfo.billingDevicesQuantity }
  }
}

const openDevicesModal = () => {
  store.dispatch('openDevices')
}

const openUpdateSubscription = () => {
  store.dispatch('handleUpdateSubscription')
}

const openUpdatePaymentMethod = () => {
  store.dispatch('openUpdatePaymentMethod')
}

watchEffect(()=>{
  mapPropsToState()
})


</script>

<template>
  <template v-if="message">
    {{ message }}
  </template>
  <template
    v-else-if="type === 'SUBSCRIPTION_UPGRADED'"
  >
    {{ $t('components.notificationsSider.screensAdded', {addedDevices: data.addedDevices}) }} {{ $t('components.notificationsSider.currentDevicesNumber', {currentDevices: data.currentDevices}) }}
    <a-typography-text
      class="underline-link"
      @click="openDevicesModal"
    >
      {{ $t('components.notificationsSider.manageScreensLink') }}
    </a-typography-text>
  </template>
  <template
    v-else-if="type === 'SUBSCRIPTION_DOWNGRADED'"
  >
    {{ $t('components.notificationsSider.screensRemoved', {removedDevices: data.removedDevices}) }} {{ $t('components.notificationsSider.currentDevicesNumber', {currentDevices: data.currentDevices}) }}
    <a-typography-text
      class="underline-link"
      @click="openDevicesModal"
    >
      {{ $t('components.notificationsSider.manageScreensLink') }}
    </a-typography-text>
  </template>
  <template
    v-else-if="type === 'SUBSCRIPTION_PAST_DUE'"
  >
    <i18n-t
      keypath="components.notificationsSider.paymentUnsuccessful"
      tag="span"
    >
      <a-typography-text
        class="underline-link"
        @click="openUpdatePaymentMethod"
      >
        {{ $t('components.notificationsSider.paymentUnsuccessfulPaymentMethod') }}
      </a-typography-text>
    </i18n-t>
  </template>
  <template
    v-else-if="type === 'SUBSCRIPTION_UNPAID'"
  >
    <i18n-t
      keypath="components.notificationsSider.subscriptionStopped"
      tag="span"
    >
      <a-typography-text
        class="underline-link"
        @click="openUpdateSubscription"
      >
        {{ $t('components.notificationsSider.subscriptionStoppedUpdate') }}
      </a-typography-text>
    </i18n-t>
  </template>
  <template
    v-else-if="type === 'SUBSCRIPTION_TO_INACTIVE'"
  >
    <i18n-t
      keypath="components.notificationsSider.subscriptionInactive"
      tag="span"
    >
      <a-typography-text
        class="underline-link"
        @click="openUpdateSubscription"
      >
        {{ $t('components.notificationsSider.subscriptionInactiveUpdate') }}
      </a-typography-text>
    </i18n-t>
  </template>
</template>

<style scoped lang="less">

</style>
