<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const props = defineProps({
   data: {
     type: Object,
     required: true
   }
 })

 const store = useStore()

 const userId = computed(() => store.getters['auth/userId'])

 const isCurrentUser = computed(() => userId.value === props.data.user.id)
 const userName = computed(() => `${props.data.user.firstName} ${props.data.user.lastName}`.trim())

 const type = computed(() => {
   if (props.data.newRole === 'OWNER') {
      return isCurrentUser.value ? 'ownerAddedForYou' : 'ownerAddedForSomeone'
   }
    let baseType =  props.data.oldRole
      ? (props.data.newRole ? 'roleChanged' : 'roleRemoved')
      : 'roleAdded'
    return isCurrentUser.value ? `${baseType}ForYou` : `${baseType}ForSomeone`
 })

</script>

<template>
  {{ $t(`components.notificationsSider.${type}`, { newRole: data.newRole, name: userName }) }}
</template>

<style scoped lang="less">

</style>
